export const iconColors = {
    primaryBlue: '#002664',
    secondaryBlue: '#2e5299',
    darkGrey: '#242934',
    white: '#ffffff',
}

export const iconSpacing = {
    sm: 16,
    md: 24,
    lg: 36,
    xl: 48,
    xxl: 60,
}

export function getSize(size: string | number) {
    if (typeof size === 'string') {
        return iconSpacing[size] || size
    } else if (typeof size === 'number') {
        return size
    }
}

export function getSystemColor(color: string) {
    return String(iconColors[color] || color)
}
