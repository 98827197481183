import { contract, gel3ColorValue, mq } from './themeContract'
import { createTheme } from './lib/createTheme'
import { gel2Color } from './gel2Theme'
import { linearGradient } from './linearGradient'
import { hexToRgb } from './lib/utils'

export const gel3LightTheme = createTheme(contract, {
    name: 'gel-3-light',
    type: 'light',
    matches: [mq.light()],
    colors: {
        interactive: {
            // temporary override to match gel2
            primary: {
                default: gel2Color.red.primary,
                hover: '#bf0c2a',
                active: '#C03D4B',
                disabled: gel2Color.grey.geyser,
                disabledText: gel2Color.grey.shuttle,
            },
        },
    },
})

export const gel3DarkTheme = createTheme(contract, {
    name: 'gel-3-dark',
    matches: [mq.dark()],
    type: 'dark',
    colors: {
        /* ---------------------------------- */
        /* START GEL3 APPROVED COLORS         */
        /* ---------------------------------- */
        brand: {
            dark: gel3ColorValue.blue04,
            light: gel3ColorValue.blue01,
            supplementary: gel3ColorValue.blue03,
            accent: gel3ColorValue.red04,
            heroBusiness: gel3ColorValue.blue02,
            heroPersonal: '#121212',
        },
        text: {
            default: gel3ColorValue.offWhite,
            link: gel3ColorValue.blue04,
            linkVisited: gel3ColorValue.purple04,
            linkHover: 'rgb(169, 187, 223)',
            subtle: gel3ColorValue.grey03,
            reversed: gel3ColorValue.grey01,
            error: gel3ColorValue.red03,
            linkReversed: gel3ColorValue.blue01,
        },
        neutral: {
            grey100: gel3ColorValue.offWhite,
            grey200: gel3ColorValue.grey04,
            grey300: gel3ColorValue.grey03,
            grey400: gel3ColorValue.grey02,
            grey500: gel3ColorValue.grey01,
        },
        background: {
            'default': '#121212',
            'cover-rgb': hexToRgb('#121212').join(','),
            'cover': `rgba($colors.background.cover-rgb, $colors.background.cover-alpha)`,
            'subtle': gel3ColorValue.grey01,
            'level1': '#353B3F',
            'median': gel3ColorValue.grey02,
            'link-rgb': hexToRgb(gel3ColorValue.blue04).join(','),
            'linkHover': `rgba($colors.background.link-rgb, 0.1)`,
            'linkActive': `rgba($colors.background.link-rgb, 0.2)`,
            'surface': {
                default: '#121212',
                subtle: gel3ColorValue.grey01,
            },
            'status': {
                info: gel3ColorValue.status.infoBlue.dark,
                success: gel3ColorValue.status.successGreen.dark,
                warning: gel3ColorValue.status.warningOrange.dark,
                error: gel3ColorValue.status.errorRed.dark,
                infoReversed: gel3ColorValue.status.infoBlue.background,
                successReversed: gel3ColorValue.status.successGreen.background,
                warningReversed: gel3ColorValue.status.warningOrange.background,
                errorReversed: gel3ColorValue.status.errorRed.background,
            },
        },
        icon: {
            info: gel3ColorValue.status.infoBlue.background,
            success: gel3ColorValue.status.successGreen.background,
            warning: gel3ColorValue.status.warningOrange.background,
            error: gel3ColorValue.status.errorRed.background,
        },
        border: {
            default: gel3ColorValue.grey04,
            subtle: gel3ColorValue.grey02,
            median: gel3ColorValue.grey03,
            brand: gel3ColorValue.blue04,
            focus: gel3ColorValue.offWhite,
            error: gel3ColorValue.status.errorRed.background, // DOES NOT EXIST: gel3ColorValue.status.errorRedLight,
            errorSubtle: gel3ColorValue.grey01,
        },
        /* END */
        skeleton: {
            background: '#5F686D',
            gradient: linearGradient(
                135,
                [
                    'hsla(0, 0%, 100%, 0)',
                    'hsla(0, 0%, 100%, 0.05)',
                    'hsla(0, 0%, 100%, 0.1)',
                    'hsla(0, 0%, 100%, 0)',
                ],
                {
                    left: 0,
                    right: 0,
                },
            ),
        },

        autosuggest: {
            text: '$colors.text.default',
        },
        breadcrumb: {
            icon: '$colors.border.default',
        },
        calendar: {
            default: '$colors.neutral.grey500',
        },
        checkbox: {
            default: '$colors.brand.dark',
            icon: gel3ColorValue.black,
        },
        forms: {
            accent: '$colors.neutral.grey400',
            helpText: '$colors.text.default',
            border: '1px solid $colors.border.default',
            checked: '$colors.brand.dark',
            disabled: {
                selected: '$colors.border.median',
                border: '$colors.border.median',
            },
        },
        keepRecord: {
            icon: '$colors.brand.dark',
        },
        progressStep: {
            text: '$colors.text.default',
        },
        radio: {
            editorBorder: '$colors.border.median',
        },

        /* ---------------------------------- */
        /* START BUTTON COLORS           */
        /* ---------------------------------- */
        interactive: {
            primary: {
                ...{
                    default: '$colors.brand.dark',
                    hover: '#BDDCEB',
                    active: '$colors.interactive.primary.default',
                    disabled: '#58656B',
                    text: '#000000',
                    border: gel3ColorValue.blue04,
                },
                ...{
                    default: '#FFE5EA',
                    hover: '#FFEAED',
                    active: '#FFE5EA',
                    disabled: '#6C6364',
                },
            },
            secondary: {
                default: 'transparent',
                hover: '#BDDCEB',
                active: gel3ColorValue.blue04,
                disabled: '$colors.interactive.secondary.default',
                border: gel3ColorValue.blue04,
                text: '$colors.interactive.secondary.border',
                disabledBorder: '#58656B',
            },
            tertiary: {
                default: 'transparent',
                hover: '#BDDCEB',
                active: gel3ColorValue.blue04,
                disabled: '#58656B',
                text: '$colors.interactive.secondary.border',
            },
            subtle: {
                default: '$colors.background.subtle',
                hover: 'gel3ColorValue.grey03',
                text: '$colors.text.default',
            },
            destructive: {
                default: gel3ColorValue.status.errorRed.background,
                hover: '#E6D7DB',
                active: '$colors.interactive.destructive.default',
                disabled: '#696364',
                text: '#000000',
            },
            link: {
                hover: '$colors.background.linkHover',
                hoverText: '$colors.text.link',
                active: '$colors.background.linkActive',
            },
        },
        /* END */
    },
    shadow: {
        // default: '0px 4px 12px 0px rgba(203, 237, 253, 0.2)',
        // hover: '0px 4px 12px 0px rgba(203, 237, 253, 0.25)',
        autosuggest: 'none',
    },
})
