import styled from 'styled-components'
import { Heading } from '@snsw-gel/content'
import { vars, mq } from '@snsw-gel/theming'
import { pxToRem } from '@snsw-gel/theming'

const StyledSectionHeader = styled.header`
    background: ${vars.colors.background.subtle};
    padding: ${vars.spacing.sm} 0;

    ${mq.min('tablet')} {
        padding: ${vars.spacing.lg} 0 ${vars.spacing.md} 0;
    }

    ${mq.print()} {
        padding: 0;
        background: 0;
    }

    h1 {
        &.heading-1 {
            margin: 0;
            max-width: ${pxToRem(768)};
        }
    }

    p {
        margin-top: ${vars.spacing.xs};
        margin-bottom: 0;
        max-width: ${pxToRem(768)};
    }

    .notice:last-of-type {
        margin-bottom: 0;
    }

    @media print {
        .container {
            border-bottom: 2px solid ${vars.colors.border.median};
            padding: 0 0 25pt;
        }
    }

    .page-header__label {
        /* note :match PageLabel styles */
        font-size: ${pxToRem(14)};
        line-height: ${vars.font.lineHeight.heading4};
    }
`

export const StyledFormHeader = styled(StyledSectionHeader)`
    padding: ${vars.spacing.sm} 0 ${vars.spacing.sm} 0;

    ${mq.min('tablet')} {
        padding: ${vars.spacing.md} 0 ${vars.spacing.md} 0;
    }

    h1 {
        &.heading-1 {
            margin-top: ${vars.spacing.sm};
            margin-bottom: 0;
            max-width: ${pxToRem(768)};

            ${mq.min('tablet')} {
                margin-top: ${vars.spacing.md};
            }
        }
    }

    p {
        margin: 0;
    }
`

export const PageNav = styled.div`
    margin-bottom: ${vars.spacing.sm};
`

export const PageLabel = styled.span`
    font-size: ${pxToRem(14)};
    line-height: ${vars.font.lineHeight.heading4};
`

export const PageTitle = styled(Heading).attrs({ level: 1 })`
    + * {
        margin-top: ${vars.spacing.sm};
        margin-bottom: 0;

        ${mq.min('tablet')} {
            margin-top: ${vars.spacing.xl};
            margin-bottom: ${vars.spacing.md};
        }
    }
`

export default StyledSectionHeader
