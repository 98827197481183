import { contract, gel3ColorValue, mq } from './themeContract'
import { createTheme } from './lib/createTheme'
import { linearGradient } from './linearGradient'
import { hexToRgb } from './lib/utils'

export const gel2Color = {
    white: '#ffffff',
    black: '#000000',
    grey: {
        dark: '#242934',
        shuttle: '#646974',
        geyser: '#dee3e5',
        mystic: '#ecf1f3',
    },
    ice: '#f4f7f9',
    aquamentus: '#008A07',
    norwester: '#9aa8af',
    frostee: '#e6f0f3',
    shiraz: '#b81237',
    cherub: '#f4dbe1',
    tawny: '#C95000',
    oldLace: '#f9f0e7',
    blue: {
        primary: '#002664',
        secondary: '#2e5299',
    },
    red: {
        primary: '#d7153a',
    },
    UNSET: 'rgba(0, 255, 0, 0.7)',
} as const

export const gel2LightTheme = createTheme(contract, {
    name: 'gel-2-light',
    type: 'light',
    matches: [mq.light()],
    font: {
        /* ---------------------------------- */
        /* START GEL3 APPROVED FONT         */
        /* ---------------------------------- */
        body: '"Gotham", Arial, sans-serif',
        weight: {
            normal: '300',
            medium: '500',
            bold: '700',
            mediumLow: '$font.weight.medium',
            accent: 'initial',
        },
        lineHeight: {
            heading1: {
                '@initial': '1.4',
                '@tablet': '1.4',
            },
            heading2: {
                '@initial': '$font.lineHeight.heading1',
                '@tablet': '$font.lineHeight.heading1',
            },
            heading3: {
                '@initial': '$font.lineHeight.heading1',
                '@tablet': '$font.lineHeight.heading1',
            },
            heading4: {
                '@initial': '$font.lineHeight.heading1',
                '@tablet': '$font.lineHeight.heading1',
            },
            heading5: {
                '@initial': '$font.lineHeight.body',
                '@tablet': '$font.lineHeight.body',
            },
            bodySmall: '1.5',
        },
        /* END */

        calendar: {
            label: '$font.size.lg', // TODO: replace with GEL 2 h3
        },
        progressStep: {
            text: '$font.size.xs',
            currentText: '$font.size.sm',
        },
        radio: {
            editorText: '$font.size.base',
            editorLineHeight: '$font.lineHeight.body',
        },
    },
    typeSize: {
        sm: {
            '@initial': '$font.size.base',
            '@tablet': '$font.size.base',
        },
        md: {
            '@initial': '$font.size.sm',
            '@tablet': '$font.size.sm',
        },
        lg: {
            '@initial': '$font.size.md',
            '@tablet': '$font.size.lg',
        },
        xl: {
            '@initial': '$font.size.xl',
            '@tablet': '$font.size.xxl',
        },
        xxl: {
            '@initial': '$font.size.xxxl',
            '@tablet': '$font.size.xxxxl',
        },
    },
    layouts: {
        accordion: {
            contentBorderWidth: 4,
        },
        calendar: {
            alignItems: 'center',
        },
        checkbox: {
            strokeLine: 'round',
            strokeWidth: '2.4',
            width: '22px',
            height: '18px',
        },
        heading1: {
            top: {
                '@initial': '$spacing.md',
                '@tablet': '$spacing.xl',
            },
            bottom: {
                '@initial': '$spacing.lg',
                '@tablet': '$spacing.xxl',
            },
        },
        icons: {
            size: 16,
            margin: '4px 0 0 0',
        },
        accentBorderWidth: 6,
        notification: {
            compactBorderWidth: '6px solid',
            compactPadding: '$spacing.sm',
            flexFlow: {
                '@initial': 'row wrap',
                '@lgMobile': 'nowrap',
            },
            iconPadding: {
                '@initial': '$spacing.sm',
                '@tablet': '$spacing.sm',
            },
            childMargin: {
                '@initial': 'none',
                '@lgMobile': '$spacing.xl',
            },
        },
        select: {
            size: 16,
            margin: 16,
        },
        validation: {
            padding: 'none',
            margin: '0 0 0 12px',
        },
    },
    colors: {
        /* ---------------------------------- */
        /* START GEL3 APPROVED COLORS         */
        /* ---------------------------------- */
        brand: {
            dark: gel2Color.blue.primary,
            light: gel2Color.blue.secondary,
            supplementary: gel3ColorValue.blue02,
            accent: gel2Color.red.primary,
            heroBusiness: gel3ColorValue.blue02,
            heroPersonal: gel3ColorValue.red02,
        },
        text: {
            default: gel2Color.grey.dark,
            subtle: gel2Color.grey.shuttle,
            reversed: gel2Color.white,
            error: gel2Color.shiraz,
            link: gel2Color.blue.secondary,
            linkReversed: gel3ColorValue.blue04,
            linkVisited: gel2Color.blue.secondary,
        },
        neutral: {
            grey100: gel2Color.grey.dark,
            grey200: gel2Color.grey.shuttle,
            grey300: gel2Color.grey.geyser,
            grey400: gel2Color.grey.mystic,
            grey500: gel2Color.ice,
        },
        background: {
            'scrim-rgb': hexToRgb(gel2Color.black).join(','),
            'scrim': `rgba($colors.background.scrim-rgb, $colors.background.scrim-alpha)`,
            'subtle': gel2Color.ice,
            'level1': gel2Color.white,
            'median': gel2Color.grey.geyser,
            'linkHover': 'none',
            'linkActive': 'none',
            'surface': {
                subtle: gel2Color.ice,
            },
            'status': {
                info: '#EAEDF4',
                success: '#E5F6E6',
                warning: '#FCEFE6',
                error: gel2Color.cherub,
                neutral: gel2Color.grey.geyser,
                infoReversed: gel2Color.blue.secondary,
                successReversed: gel2Color.aquamentus,
                warningReversed: gel2Color.tawny,
                errorReversed: gel2Color.shiraz,
            },
        },
        icon: {
            info: gel2Color.blue.secondary,
            success: gel2Color.aquamentus,
            warning: gel2Color.tawny,
            error: gel2Color.shiraz,
        },
        border: {
            default: gel2Color.grey.shuttle,
            subtle: gel2Color.grey.mystic,
            median: gel2Color.grey.geyser,
            brand: gel2Color.blue.primary,
            focus: gel2Color.blue.secondary,
            error: gel2Color.shiraz,
            errorSubtle: gel2Color.white,
        },
        /* END */

        autosuggest: {
            text: gel2Color.white,
            border: '$colors.forms.border',
            borderTop: {
                default: '1px solid $colors.border.median',
                address: '$colors.autosuggest.borderTop.default',
            },
        },
        breadcrumb: {
            icon: gel2Color.grey.dark,
        },
        calendar: {
            default: '$colors.neutral.grey400',
        },
        checkbox: {
            default: 'none',
            icon: '$colors.border.default',
        },
        dropzone: {
            icon: {
                default: '$colors.forms.helpText',
            },
        },
        forms: {
            accent: '$colors.neutral.grey300',
            helpText: '$colors.text.subtle',
            border: '2px solid $colors.border.default',
            checked: '$colors.neutral.grey200',
            disabled: {
                selected: 'none',
                border: '$colors.border.default',
            },
        },
        keepRecord: {
            icon: gel2Color.grey.dark,
        },
        progressStep: {
            text: gel2Color.blue.primary,
        },
        radio: {
            editorBorder: '#D9D9D9',
        },

        horizontalRule: {
            default: gel2Color.grey.shuttle,
        },

        validation: {
            text: '$colors.text.error',
            success: 'none',
            error: 'none',
        },

        skeleton: {
            background: 'rgba(222, 227, 229, 0.8)',
            gradient: linearGradient(
                135,
                [
                    'hsla(0, 0%, 100%, 0)',
                    'hsla(0, 0%, 100%, 0.4)',
                    'hsla(0, 0%, 100%, 0.6)',
                    'hsla(0, 0%, 100%, 0)',
                ],
                {
                    left: 0,
                    right: 0,
                },
            ),
        },

        /* ---------------------------------- */
        /* START BUTTON COLORS           */
        /* ---------------------------------- */
        interactive: {
            primary: {
                default: gel2Color.red.primary,
                hover: '#bf0c2a',
                active: '#C03D4B',
                disabled: gel2Color.grey.geyser,
                disabledText: gel2Color.grey.shuttle,
            },

            secondary: {
                default: '#F9FDFF',
                text: gel2Color.blue.secondary,
                disabled: gel2Color.grey.geyser,
                hover: gel2Color.blue.secondary,
                hoverText: '#ffffff',
                active: '#5975AD',
                disabledText: gel2Color.grey.shuttle,
                disabledBorder: '$colors.interactive.secondary.disabled',
            },
            tertiary: {
                text: gel2Color.blue.secondary,
                disabledText: gel2Color.grey.shuttle,
                underlines: 'underline',
            },
            link: {
                hover: gel2Color.blue.secondary,
                hoverText: gel2Color.white,
                active: gel2Color.blue.secondary,
            },
        },
        /* END */

        disabled: {
            default: gel2Color.grey.geyser,
            text: gel2Color.grey.shuttle,
            off: gel2Color.grey.geyser,
        },
        selected: {
            default: gel2Color.blue.secondary,
            text: gel2Color.white,
            off: gel2Color.grey.geyser,
        },
    },

    radius: {
        regular: 8,
        checkbox: 0,
    },
    shadow: {
        default: `0px 2px 4px -1px rgba(19, 36, 69, 0.1),
        0px 4px 5px 0px rgba(19, 36, 69, 0.05),
        0px 1px 10px 0px rgba(19, 36, 69, 0.05)`,
        hover: `0px 5px 5px -3px rgba(19, 36, 69, 0.1),
        0px 8px 10px 1px rgba(19, 36, 69, 0.05),
        0px 3px 14px 2px rgba(19, 36, 69, 0.05)`,
        autosuggest: '0 2px 18px 0 rgba(0, 0, 0, 0.05)',
    },
    textDecoration: {
        autosuggest: 'none',
    },
})
