import React from 'react'
import { SROnly } from '@snsw-gel/accessibility'
import AnimatedIconSuccess from './AnimatedIconSuccess'
import {
    Dimmer,
    LoaderContainer,
    LoaderMessage,
    SpinnerImageContainer,
} from '@snsw-gel/loader'

interface SuccessIndicatorProps {
    className?: string
    active?: boolean
    label: string
    [key: string]: any
}

export const SuccessIndicator: React.FC<SuccessIndicatorProps> = ({
    className = '',
    active = false,
    label,
    ...rest
}: SuccessIndicatorProps) => {
    return (
        <Dimmer className={className} active={active} fullPage {...rest}>
            <SROnly role='status'>{label}</SROnly>
            <LoaderContainer data-testid='hide-items' aria-hidden={true}>
                <SpinnerImageContainer>
                    <AnimatedIconSuccess />
                </SpinnerImageContainer>
                <LoaderMessage className='loader-message--full-page'>
                    {label}
                    <br />
                </LoaderMessage>
            </LoaderContainer>
        </Dimmer>
    )
}
