import React from 'react'
import { mt, mb, StyledHorizontalRule } from './HorizontalRule.styled'

export type HrProps = {
    className?: string
    marginTop?: string
    marginBottom?: string
}

export const HorizontalRule = (props: HrProps) => {
    return (
        <StyledHorizontalRule
            {...props}
            style={{
                ...(props.marginTop ? mt.setStyle(props.marginTop) : {}),
                ...(props.marginBottom ? mb.setStyle(props.marginBottom) : {}),
            }}
        />
    )
}

// @ts-ignore
HorizontalRule.displayName = 'HorizontalRule'
