/* eslint-disable max-len */
import React, { Fragment, ReactNode, Ref } from 'react'
import {
    StyledInPageAlert,
    StyledInPageAlertChildren,
    StyledInPageAlertIcon,
    StyledInPageAlertTitle,
    StyledInPageAlertWrapper,
    StyledInPageAlertCompact,
} from './InPageAlert.styled'
import { HeadingProps } from '@snsw-gel/content'
import {
    IconNotificationSuccess,
    IconNotificationError,
    IconNotificationWarning,
    IconNotificationInfo,
} from '@snsw-gel/icons'
import { deprecation, forwarded } from '@snsw-gel/utils'
import classNames from 'classnames'

const icons = {
    error: <IconNotificationError size='md' />,
    warning: <IconNotificationWarning size='md' />,
    success: <IconNotificationSuccess size='md' />,
    info: <IconNotificationInfo size='md' />,
    callout: null,
} as const

export interface InPageAlertProps {
    id?: string
    className?: string
    title: string
    variant: keyof typeof icons
    role?: 'alert' | 'status'
    children?: ReactNode
    headingElement?: HeadingProps['headingElement']
    compact?: boolean
    innerRef?: Ref<HTMLInputElement>
    /**
     * headingLevel is deprecated. Use headingElement instead.
     * @deprecated
     */
    headingLevel?: HeadingProps['headingElement']
}

const InPageAlertHeadingLevel = {
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p: 'p',
} as const

export const InPageAlert = forwarded(
    (props: InPageAlertProps, ref: Ref<HTMLDivElement>) => {
        const {
            variant,
            className,
            title,
            headingLevel,
            headingElement = headingLevel || 'h6',
            children,
            role,
            compact = false,
            innerRef,
            ...rest
        } = props

        const refToUse = ref || innerRef

        const displayIcon = icons[variant]

        deprecation('innerRef' in props, `props.innerRef`)
        deprecation('headingLevel' in props, `InPageAlert.headingLevel`)

        let headingLevelToRender = headingElement

        if (!(headingLevelToRender in InPageAlertHeadingLevel)) {
            headingLevelToRender = InPageAlertHeadingLevel.h6
        }

        const TitleWrapper = displayIcon ? StyledInPageAlertWrapper : Fragment

        return (
            <StyledInPageAlert
                className={classNames(className, `--${variant}`, {
                    '--compact': compact,
                })}
                role={role}
                ref={refToUse}
                {...rest}
            >
                <TitleWrapper className={classNames({ '--compact': compact })}>
                    {displayIcon && (
                        <StyledInPageAlertIcon
                            className={classNames(`icon--${variant}`, {
                                'icon--compact': compact || !children,
                            })}
                        >
                            {displayIcon}
                        </StyledInPageAlertIcon>
                    )}
                    {compact ? (
                        <StyledInPageAlertCompact>
                            <StyledInPageAlertTitle
                                level={5}
                                headingElement={headingLevelToRender}
                            >
                                {title}
                            </StyledInPageAlertTitle>
                            {Boolean(compact && children) && (
                                <StyledInPageAlertChildren>
                                    {children}
                                </StyledInPageAlertChildren>
                            )}
                        </StyledInPageAlertCompact>
                    ) : (
                        <StyledInPageAlertTitle
                            level={5}
                            className={classNames({ 'title-only': !children })}
                            headingElement={headingLevelToRender}
                        >
                            {title}
                        </StyledInPageAlertTitle>
                    )}
                </TitleWrapper>
                {Boolean(!compact && children) && (
                    <StyledInPageAlertChildren
                        className={classNames(
                            variant === 'callout' && '--callout',
                        )}
                    >
                        {children}
                    </StyledInPageAlertChildren>
                )}
            </StyledInPageAlert>
        )
    },
)
