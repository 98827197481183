import styled from 'styled-components'
import { pxToRem } from '@snsw-gel/theming'
import { IconClose } from '@snsw-gel/icons'
import { vars } from '@snsw-gel/theming'

export const FullScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const FullScreenOpac = styled(FullScreen)`
    background-color: #848484;
    opacity: 0.8;
    transition: opacity ${vars.transitions.idle} ${vars.easings.natural};

    &.out {
        opacity: 0;
    }
`

export const StyledSlidingModal = styled.div`
    position: relative;
    min-height: 4rem;
    z-index: 2001;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: 100%;
    justify-content: flex-start;
    overflow-y: auto;
    &:focus {
        outline: 3px solid ${vars.colors.focus};
        outline-offset: -6px;
    }
`

StyledSlidingModal.DismissButton = styled.button`
    position: absolute;
    top: ${pxToRem(8)};
    right: ${pxToRem(8)};
    width: ${pxToRem(48)};
    height: ${pxToRem(48)};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: none;
    &:focus {
        outline: 2px solid ${vars.colors.focus};
        outline-offset: 2px;
    }
    &:before {
        content: '';
        position: absolute;
        top: auto;
        left: auto;
        width: ${pxToRem(48)};
        height: ${pxToRem(48)};
    }
`

StyledSlidingModal.CloseIcon = styled(IconClose).attrs({
    color: '#000',
    width: '1rem',
    height: '1rem',
})`
    overflow: visible;
`
