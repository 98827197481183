import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconInformationProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconInformation = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconInformationProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Information_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Information_svg__cls-1'
                d='M24 48a24 24 0 1124-24 24 24 0 01-24 24zm0-46a22 22 0 1022 22A22 22 0 0024 2z'
            />
            <path
                className='Information_svg__cls-1'
                d='M28 41h-8a1 1 0 01-1-1V19a1 1 0 011-1h8a1 1 0 011 1v21a1 1 0 01-1 1zm-7-2h6V20h-6z'
            />
            <path d='M29 11a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    )
}
