import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconNotificationWarningProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconNotificationWarning = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconNotificationWarningProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            fill={displayColor}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g>
                <g>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M9.00334 10V7C9.00334 6.44772 8.55562 6 8.00334 6C7.45105 6 7.00334 6.44772 7.00334 7V10C7.00334 10.5523 7.45105 11 8.00334 11C8.55562 11 9.00334 10.5523 9.00334 10ZM8.38334 13.92C8.50452 13.8694 8.61614 13.7983 8.71334 13.71C8.75634 13.6625 8.79642 13.6124 8.83334 13.56C8.87118 13.5043 8.90145 13.4437 8.92334 13.38C8.95913 13.3245 8.98612 13.2638 9.00334 13.2C9.00779 13.1334 9.00779 13.0666 9.00334 13C9.00176 12.8693 8.97458 12.7402 8.92334 12.62C8.87574 12.4972 8.80438 12.3851 8.71334 12.29C8.42578 12.0063 7.99573 11.9234 7.62334 12.08C7.50215 12.1306 7.39054 12.2017 7.29334 12.29C7.2023 12.3851 7.13093 12.4972 7.08334 12.62C7.0321 12.7402 7.00492 12.8693 7.00334 13C6.99889 13.0666 6.99889 13.1334 7.00334 13.2C7.01428 13.2626 7.0345 13.3233 7.06334 13.38C7.08523 13.4437 7.11549 13.5043 7.15334 13.56L7.27334 13.71C7.37054 13.7983 7.48215 13.8694 7.60334 13.92C7.84538 14.027 8.1213 14.027 8.36334 13.92H8.38334Z'
                        fill='white'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M7.03087 1.09896C7.43018 0.300345 8.56984 0.300346 8.96915 1.09896L15.8842 13.9291C16.2444 14.6495 15.7205 15.4972 14.9151 15.4972H1.08493C0.279452 15.4972 -0.244432 14.6495 0.11579 13.9291L7.03087 1.09896ZM8.38334 13.4182C8.50452 13.3676 8.61614 13.2965 8.71334 13.2082C8.75634 13.1607 8.79642 13.1106 8.83334 13.0582C8.87118 13.0025 8.90145 12.9419 8.92334 12.8782C8.95913 12.8227 8.98612 12.762 9.00334 12.6982C9.00779 12.6316 9.00779 12.5648 9.00334 12.4982C9.00176 12.3675 8.97458 12.2384 8.92334 12.1182C8.87574 11.9955 8.80438 11.8833 8.71334 11.7882C8.42578 11.5045 7.99573 11.4216 7.62334 11.5782C7.50215 11.6289 7.39054 11.6999 7.29334 11.7882C7.2023 11.8833 7.13093 11.9955 7.08334 12.1182C7.0321 12.2384 7.00492 12.3675 7.00334 12.4982C6.99889 12.5648 6.99889 12.6316 7.00334 12.6982C7.01428 12.7609 7.0345 12.8215 7.06334 12.8782C7.08523 12.9419 7.11549 13.0025 7.15334 13.0582L7.27334 13.2082C7.37054 13.2965 7.48215 13.3676 7.60334 13.4182C7.84538 13.5252 8.1213 13.5252 8.36334 13.4182H8.38334ZM9 6.5V9.5C9 10.0523 8.55229 10.5 8 10.5C7.44772 10.5 7 10.0523 7 9.5V6.5C7 5.94772 7.44772 5.5 8 5.5C8.55229 5.5 9 5.94772 9 6.5Z'
                        fill='#DC5800'
                    />
                </g>
            </g>
        </svg>
    )
}
