import React, { memo, PropsWithChildren, useEffect, useState } from 'react'
import { mq, vars } from './themeContract'
import { createGlobalStyle } from 'styled-components'
import { printThemesCss } from './lib/createTheme'
import { gel2LightTheme } from './gel2Theme'
import { StyledTestingElement } from './TestingElement.styled'
import { createPortal } from 'react-dom'
import { Theme, ThemeImplementation } from './lib/themeTypes'
import { pxToRem } from './lib/pxToRem'

type GlobalStyleProps<Theme extends ThemeImplementation<any>> = {
    themes?: Theme[]
}

const logCache = new Set()
const log = (...args: any[]) => {
    if (logCache.has(args.join(' '))) {
        return
    }
    logCache.add(args.join(' '))
    console.log(...args)
}

const TestingElement = SNAPSHOT_RELEASE
    ? () => {
          const [element, setElement] = useState<HTMLElement>()
          log(`@snsw-gel/react@${GEL_VERSION}`)
          useEffect(() => {
              const div = document.createElement('div')
              div.id = 'testing-element'
              document.body.appendChild(div)

              setElement(div)

              return () => {
                  document.body.removeChild(div)
              }
          }, [])

          if (!element) {
              return null
          }
          return createPortal(
              <StyledTestingElement aria-hidden>
                  @snsw-gel/react@{GEL_VERSION}
              </StyledTestingElement>,
              element,
          )
      }
    : () => null

const GlobalStylesComponent = createGlobalStyle<
    Required<GlobalStyleProps<ThemeImplementation<any>>>
>`
    ${props => printThemesCss(...props.themes)}

    html, body, .theme-root {
        font-family: ${vars.font.body};
        font-size: 100%;
        font-weight: ${vars.font.weight.normal};
        line-height: ${vars.font.lineHeight.body};
        box-sizing: border-box;
        color: ${vars.colors.text.default};
        background: ${vars.colors.background.default};
    }

    @media (prefers-reduced-motion: reduce) {
        :root {
            ${vars.transitions.multiplier.set('0')}
        }
    }

    html.no-animation {
        ${vars.transitions.multiplier.set('0')}
    }

    html.reduced-animation {
        ${vars.transitions.multiplier.set('0.2')}
    }

    input,
    select,
    textarea,
    button,
    option,
    optgroup,
    output,
    datalist {
        font-family: inherit;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    html,
    body {
        height: 100%;
    }

    body {
        -webkit-font-smoothing: antialiased;
    }

    a {
        color: ${vars.colors.text.link};
        text-decoration: none;
        background-color: transparent;
        -webkit-text-decoration-skip: ink;
        text-decoration-skip-ink: auto;

        transition-duration: ${vars.transitions.idle};
        transition-property: color;
        transition-timing-function: ${vars.easings.natural};

        &:hover {
            color: ${vars.colors.text.linkHover};
            transition-duration: ${vars.transitions.hover};
        }

        &:active, &:visited {
            transition-duration: ${vars.transitions.active};
        }

        &:visited {
            color: ${vars.colors.text.linkVisited};
        }
    }

    ol,
    ul {
        margin: ${vars.spacing.sm} 0;
    }
    ${mq.min('tablet')} {
        ol,
        ul {
            margin: ${vars.spacing.md} 0;
            padding: 0 0 0 ${pxToRem(30)};
        }
    }
    ${mq.print()} {
        ol,
        ul {
            margin-left: ${pxToRem(36)};
            padding: 0;
        }
    }
    ol ol,
    ol ul,
    ul ol,
    ul ul {
        margin-top: ${pxToRem(12)};
        margin-bottom: 0;
    }
    li {
        margin: 0 0 ${pxToRem(12)};
    }

    ${mq.highContrast()} {
        ::marker {
            color: canvastext;
        }
    }

    ol > li,
    ul > li {
        padding-left: ${pxToRem(8)};
        background: none;
    }

    ol, ul {
        padding-left: ${pxToRem(32)}
    }

    ${mq.print()} {
        ol > li,
        ul > li {
            padding: 0;
        }
    }
    ol ol {
        list-style-type: lower-alpha;
    }
    ol ol ol {
        list-style-type: lower-roman;
    }
    ul {
        list-style-type: disc;
    }
    ul ul {
        list-style-type: circle;
    }
    ul ul ul {
        list-style-type: square;
    }
    dl {
        margin: ${vars.spacing.sm} 0;
    }
    ${mq.min('tablet')} {
        dl {
            margin: ${vars.spacing.md} 0;
            padding: 0;
        }
    }
    dl > div {
        margin: 0 0 ${vars.spacing.sm};
    }
    ${mq.min('smMobile')} {
        dl > div {
            display: flex;
            flex-wrap: wrap;
        }
    }
    ${mq.min('tablet')} {
        dl > div {
            margin:  0 0 ${vars.spacing.md};
        }
    }

    dl dt {
        margin: 0;
    }
    ${mq.min('smMobile')} {
        dl dt {
            width: ${pxToRem(200)};
            padding-right: ${pxToRem(20)};
        }
    }
    dl dd {
        margin: 0;
    }
    dl dd :first-child {
        margin-top: 0;
    }
    dl dd :last-child {
        margin-bottom: 0;
    }
    .dl--important {
        max-width: none;
    }
    .dl--important dt {
        font-weight: ${vars.font.weight.medium};
    }
    ${mq.min('smMobile')} {
        .dl--important dt {
            width: ${pxToRem(300)};
        }
    }

    .mobile-only {
        display: none;
    }

    .computer-only {
        display: block;
    }

    ${mq.max('lgMobile')} {
        .mobile-only {
            display: block;
        }

        .computer-only {
            display: none;
        }
    }

    .Width-100 {
        width: 100%;
    }

    .Align-center {
        text-align: center !important;
    }
`

export const GlobalStyle = memo(function GlobalStyle<
    Themes extends ThemeImplementation<any>,
>(props: PropsWithChildren<GlobalStyleProps<Themes>>) {
    const { children, themes } = props

    let themesToRender = Array.isArray(themes) ? themes : [gel2LightTheme]

    if (!themesToRender.length) {
        themesToRender = [gel2LightTheme]
    }

    return (
        <>
            <GlobalStylesComponent themes={themesToRender} />
            <TestingElement />
            {children}
        </>
    )
})
