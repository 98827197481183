import * as React from 'react'
import type { SVGProps } from 'react'

export interface IconInfoProps {
    title?: string
    titleId?: string
}

export const IconInfo = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconInfoProps) => {
    const displaySize = '16'
    const displayColor = 'none'
    props.focusable = props.focusable ?? false
    return (
        <svg
            fill={displayColor}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                d='M8 15C4.134 15 1 11.866 1 8C1 4.134 4.134 1 8 1C11.866 1 15 4.134 15 8C15 9.85652 14.2626 11.637 12.9497 12.9497C11.637 14.2626 9.85652 15 8 15Z'
                fill='white'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2 8C2 11.3137 4.68629 14 8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8ZM0 8C0 12.4183 3.58172 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8Z'
                fill='#242934'
            />
            <path
                d='M9 8V11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11V8C7 7.44772 7.44772 7 8 7C8.55229 7 9 7.44772 9 8Z'
                fill='#242934'
            />
            <path
                d='M8.38 5.94C8.32045 5.97045 8.25624 5.99073 8.19 6H8C7.44772 6 7 5.55229 7 5C7.00568 4.59737 7.25229 4.23743 7.62571 4.08675C7.99912 3.93607 8.42648 4.02407 8.71 4.31C8.89931 4.49777 9.0058 4.75336 9.0058 5.02C9.0058 5.28664 8.89931 5.54223 8.71 5.73C8.66081 5.77525 8.60721 5.81545 8.55 5.85C8.49895 5.88958 8.44143 5.92003 8.38 5.94Z'
                fill='#242934'
            />
        </svg>
    )
}
