import styled from 'styled-components'
import { pxToRem } from '@snsw-gel/theming'
import { vars } from '@snsw-gel/theming'

export const MoreInfoModalIconButton = styled.button`
    cursor: pointer;
    position: relative;
    background: none;
    padding: 0;
    border: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    svg {
        margin: 0;
    }

    &:focus {
        outline: 2px solid ${vars.colors.focus};
        outline-offset: 2px;
    }

    &:before {
        content: '';
        position: absolute;
        top: -0.25rem;
        left: -0.25rem;
        width: ${pxToRem(24)};
        height: ${pxToRem(24)};
    }

    &:hover {
        .iconQuestion {
            circle {
                fill: #2e5299;
            }
            path {
                fill: white;
                &:nth-child(1) {
                    fill: white;
                }
                &:nth-child(2) {
                    fill: #2e5299;
                }
                &:nth-child(3) {
                    fill: white;
                }
                &:nth-child(4) {
                    fill: white;
                }
                &:nth-child(5) {
                    fill: white;
                }
            }
        }
    }

    &:hover {
        .iconInfo {
            path {
                &:nth-child(1) {
                    fill: #2e5299;
                }
                &:nth-child(2) {
                    fill: #2e5299;
                }
                &:nth-child(3) {
                    fill: white;
                }
                &:nth-child(4) {
                    fill: white;
                }
            }
        }
    }
`
