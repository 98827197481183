import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconReportsDocsProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconReportsDocs = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconReportsDocsProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.ReportsDocs_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='ReportsDocs_svg__cls-1'
                d='M32 24H12a1 1 0 000 2h20a1 1 0 000-2zm0 5H12a1 1 0 000 2h20a1 1 0 000-2zm0 5H12a1 1 0 000 2h20a1 1 0 000-2zm0 5H12a1 1 0 000 2h20a1 1 0 000-2z'
            />
            <path
                className='ReportsDocs_svg__cls-1'
                d='M34 4H16a1 1 0 00-.67.26l-10 9A1 1 0 005 14v29a5 5 0 005 5h24a5 5 0 005-5V9a5 5 0 00-5-5zm3 39a3 3 0 01-3 3H10a3 3 0 01-3-3V14.45l8-7.2V15h-5v2h7V6h17a3 3 0 013 3z'
            />
            <path
                className='ReportsDocs_svg__cls-1'
                d='M38 0H20v2h18a3 3 0 013 3v38h2V5a5 5 0 00-5-5z'
            />
            <path d='M29 19a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
        </svg>
    )
}
