import React, { ReactNode, Ref } from 'react'
import StyledSectionHeader, {
    PageNav,
    PageLabel,
    PageTitle,
} from './SectionHeader.styled'
import { forwarded, useId, deprecation } from '@snsw-gel/utils'
import { Container } from './ContentContainer.styled'
import { Breadcrumb, BreadcrumbProps } from '@snsw-gel/breadcrumb'

export interface SectionHeaderProps {
    id?: string
    className?: string
    children?: ReactNode
    label?: string
    title: string
    breadcrumbList?: BreadcrumbProps['linksList']
    intro?: string | ReactNode
    /**
     * The 'breadcrumb' prop is deprecated.
     * @deprecated
     *  */
    breadcrumb?: ReactNode
}

export const SectionHeader = forwarded(
    (props: SectionHeaderProps, ref?: Ref<HTMLDivElement>) => {
        const {
            id,
            breadcrumb,
            className,
            label,
            title,
            intro,
            children,
            breadcrumbList,
            ...rest
        } = props

        deprecation('breadcrumb' in props, `SectionHeader.breadcrumb`)

        const elemId = useId(id)

        const introText = typeof intro === 'object' ? intro : <p>{intro}</p>

        return (
            <StyledSectionHeader
                id={elemId}
                className={className}
                {...rest}
                ref={ref}
            >
                <Container>
                    {breadcrumbList ? (
                        <PageNav>
                            <Breadcrumb linksList={breadcrumbList} />
                        </PageNav>
                    ) : (
                        breadcrumb && <PageNav>{breadcrumb}</PageNav>
                    )}
                    {label && <PageLabel>{label}</PageLabel>}
                    {title && <PageTitle>{title}</PageTitle>}
                    {intro && introText}
                    {children}
                </Container>
            </StyledSectionHeader>
        )
    },
)

// @ts-ignore
SectionHeader.displayName = 'SectionHeader'
