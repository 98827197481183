import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SlidingModal from './SlidingModal'
import StyledModal from './Modal.styled'
import { Button } from '@snsw-gel/button'
import { SROnly } from '@snsw-gel/accessibility'
import { useId } from '@snsw-gel/utils'
import { MoreInfoModalIconButton } from './MoreInfoModal.styled'
import { IconInfo } from './svg/IconInfo.tsx'
import { IconQuestion } from './svg/IconQuestion.tsx'

export const MoreInfoModal = props => {
    const {
        id,
        title,
        subTitle,
        helpText,
        questionIconFlag,
        questionHyperLink,
        hyperLinKText,
        linkText: propLinkText,
    } = props

    const [isOpen, setIsOpen] = useState(false)
    const [returnFocus, setReturnFocus] = useState(null)

    if (process.env.NODE_ENV !== 'production' && 'hyperLinKText' in props) {
        console.warn(
            Error(
                '"MoreInfoModal.props.hyperLinKText" prop has been deprecated as it was exposed as a typo. Please use the "linkText" prop instead.',
            )
                .toString()
                .replace('Error:', 'Deprecation:'),
        )
    }

    const linkText = propLinkText || hyperLinKText

    const toggleModal = () => {
        setIsOpen(!isOpen)

        if (isOpen) {
            returnFocus.focus()
        } else {
            setReturnFocus(document.activeElement)
        }
    }

    const elemID = useId(id)
    const idTitle = `${elemID}-title`
    const idDesc = `${elemID}-desc`

    if (!helpText) {
        return null
    }

    return (
        <>
            {!questionHyperLink && (
                <MoreInfoModalIconButton type='button' onClick={toggleModal}>
                    {questionIconFlag && <IconInfo className='iconInfo' />}
                    {!questionIconFlag && (
                        <IconQuestion className='iconQuestion' />
                    )}
                    {linkText && <SROnly>{linkText}</SROnly>}
                </MoreInfoModalIconButton>
            )}
            {questionHyperLink && !questionIconFlag && (
                <Button onClick={toggleModal} theme='link'>
                    {linkText}
                </Button>
            )}
            <SlidingModal open={isOpen} onClose={toggleModal}>
                <StyledModal.Header id={idTitle} slidingPanel>
                    <StyledModal.Title>{title}</StyledModal.Title>
                    {subTitle}
                </StyledModal.Header>
                <StyledModal.Body id={idDesc}>{helpText}</StyledModal.Body>
            </SlidingModal>
        </>
    )
}

MoreInfoModal.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    helpText: PropTypes.node,
    questionIconFlag: PropTypes.bool,
    questionHyperLink: PropTypes.bool,
    linkText: PropTypes.string,
    hyperLinKText: PropTypes.string,
}

MoreInfoModal.defaultProps = {
    questionIconFlag: false,
    questionHyperLink: false,
}
