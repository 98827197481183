import styled from 'styled-components'
import PropTypes from 'prop-types'

const SectionPage = styled.section`
    &:first-of-type h2:first-child {
        margin-top: 0;
    }

    &:first-of-type > *:first-child {
        margin-top: 0;
    }

    &:last-of-type > *:last-child {
        margin-bottom: 0;
    }
`

SectionPage.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
}

export default SectionPage
