import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { FullScreenOpac, StyledSlidingModal } from './SlidingModal.styled'
import SlidingTransition from './SlidingTransition.styled'
import { getFocusableElement, trapTabKey } from '@snsw-gel/utils'
import { SROnly } from '@snsw-gel/accessibility'

const ANIMATION_DURATION = 400

const SlidingModal = ({ children, open, onClose }) => {
    const closeRef = useRef(null)
    const modalRef = useRef(null)
    const fullscreenRef = useRef(null)

    const handleTabKey = e => {
        const focusableElems = getFocusableElement(modalRef.current)
        trapTabKey(e, focusableElems)
    }

    const onEscClose = e => {
        onClose()
    }

    const keyListenersMap = new Map([
        [27, onEscClose],
        [9, handleTabKey],
    ])

    const [isAnimating, setAnimating] = useState(false)

    useLayoutEffect(() => {
        if (!modalRef.current || !fullscreenRef.current) {
            return
        }
        setAnimating(true)
        if (open) {
            modalRef.current.classList.add('out')
            fullscreenRef.current.classList.add('out')
            modalRef.current.offsetLeft // force layout
            fullscreenRef.current.offsetLeft // force layout
            modalRef.current.classList.remove('out')
            fullscreenRef.current.classList.remove('out')
        } else {
            fullscreenRef.current.classList.add('out')
            modalRef.current.classList.add('out')
            const timeout = setTimeout(() => {
                setAnimating(false)
            }, ANIMATION_DURATION)
            return () => clearTimeout(timeout)
        }
    }, [open])

    useEffect(() => {
        const keyListener = e => {
            const listener = keyListenersMap.get(e.keyCode)
            return listener && listener(e)
        }

        if (open) {
            closeRef.current.focus()
            document.addEventListener('keydown', keyListener)
        } else {
            document.removeEventListener('keydown', keyListener)
        }

        // check this is removed even without the return
        return () => document.removeEventListener('keydown', keyListener)
    }, [open]) // eslint-disable-line

    const show = open || isAnimating

    if (!show) {
        return null
    }

    return (
        <>
            <FullScreenOpac ref={fullscreenRef} onClick={onClose} />
            <SlidingTransition
                role='dialog'
                aria-modal='true'
                ref={modalRef}
                onAnimationEnd={event =>
                    handleAnimationEnd(event.animationName)
                }
            >
                <StyledSlidingModal tabIndex={0}>
                    <StyledSlidingModal.DismissButton
                        onClick={onClose}
                        data-test='modal-close-button'
                        ref={closeRef}
                        type='button'
                    >
                        <StyledSlidingModal.CloseIcon />
                        <SROnly>Close</SROnly>
                    </StyledSlidingModal.DismissButton>
                    {children}
                </StyledSlidingModal>
            </SlidingTransition>
        </>
    )
}

SlidingModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
}

export default SlidingModal
