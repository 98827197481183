import React, { Ref } from 'react'
import { Container, PageWrapper } from './ContentContainer.styled'
import { forwarded } from '@snsw-gel/utils'

export interface ContentContainerProps {
    className?: string
    children?: React.ReactNode
}

export const ContentContainer = forwarded(
    (props: ContentContainerProps, ref: Ref<HTMLDivElement>) => {
        const { className, children, ...rest } = props
        return (
            <PageWrapper {...rest} className={className} ref={ref}>
                <Container>{children}</Container>
            </PageWrapper>
        )
    },
)

// @ts-ignore
ContentContainer.displayName = 'ContentContainer'
