import { css } from 'styled-components'
import { vars } from '../themeContract'
import { pxToRem } from './pxToRem'

function valueToSpace(value: any) {
    let result: any = 0

    if (value) {
        if (!isNaN(Number(value))) {
            result = pxToRem(Number(value))
        } else if (typeof value === 'string') {
            if (!(value in vars.spacing)) {
                throw new Error(
                    'getSpacing function has been passed an invalid token.',
                )
            }
            // @ts-ignore
            result = String(vars.spacing[value as unknown as any])
        }
    }

    return result
}

export const getSpacing = (sizes: any) => {
    const arr = Array.isArray(sizes) ? sizes : [sizes]
    if (arr.length > 4) {
        throw new Error('getSpacing accepts arrays up to 4 values.')
    }
    // TODO: FIX GET SPACING
    return arr.map(size => valueToSpace(size)).join(' ')
}

export const marginMixin = ({ margin: value }: { margin?: any }) => {
    const marginValue = valueToSpace(value?.top) || pxToRem(16)

    return css`
        margin-top: ${marginValue};
    `
}

export const getTypeSize = (size: any, lineHeight: any = 'body') => {
    // TODO: FIX GETTYPESIZE

    return css`
        font-size: ${
            // @ts-ignore
            vars.font.size[size]
        };
        line-height: ${
            // @ts-ignore
            vars.font.lineHeight[lineHeight]
        };
    `
}

export const hexToRgb = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16)
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)
    return [r, g, b]
}
