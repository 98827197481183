import * as React from 'react'
import type { SVGProps } from 'react'

export interface IconQuestionProps {
    title?: string
    titleId?: string
}

export const IconQuestion = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconQuestionProps) => {
    const displaySize = '16'
    const displayColor = 'none'
    props.focusable = props.focusable ?? false
    return (
        <svg
            fill={displayColor}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g clipPath='url(#clip0_2618_8845)'>
                <circle cx='8.00003' cy='8.00001' r='8' fill='white' />
                <path
                    d='M8.00259 0.00928974C12.4209 0.00928974 16.0026 3.59101 16.0026 8.00929C16.0026 12.4276 12.4209 16.0093 8.00259 16.0093C3.58431 16.0093 0.00259018 12.4276 0.00259018 8.00929C0.00259018 3.59101 3.58431 0.00928974 8.00259 0.00928974ZM8.00259 2.00929C4.68888 2.00929 2.00259 4.69558 2.00259 8.00929C2.00259 11.323 4.68888 14.0093 8.00259 14.0093C11.3163 14.0093 14.0026 11.323 14.0026 8.00929C14.0026 4.69558 11.3163 2.00929 8.00259 2.00929Z'
                    fill='#242934'
                />
                <path
                    d='M8.00259 12.8101C8.55487 12.8101 9.00259 12.3624 9.00259 11.8101C9.00259 11.2578 8.55487 10.8101 8.00259 10.8101C7.45031 10.8101 7.00259 11.2578 7.00259 11.8101C7.00259 12.3624 7.45031 12.8101 8.00259 12.8101Z'
                    fill='#242934'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.05309 9.3706C7.05309 9.92288 7.5008 10.3706 8.05309 10.3706C8.60537 10.3706 9.05309 9.92288 9.05309 9.3706'
                    fill='#242934'
                />
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M4.96125 5.92867C4.96125 6.48095 5.40896 6.92867 5.96125 6.92867C6.51353 6.92867 6.96125 6.48095 6.96125 5.92867'
                    fill='#242934'
                />
                <path
                    d='M7.95677 3.17637C9.83741 3.17637 10.9894 4.24495 11.1325 6.08804L11.1443 6.296L11.1387 6.4432C11.0343 7.37412 10.5301 8.1191 9.69887 8.61828L9.51578 8.72147C9.21555 8.88013 9.08577 9.05062 9.05857 9.31889L9.05305 9.42425L7.05313 9.40648C7.06211 8.3955 7.53867 7.57772 8.40321 7.05401L8.58127 6.95322C8.91419 6.77728 9.07762 6.58411 9.13464 6.32201L9.14025 6.28337L9.1371 6.21448C9.08063 5.48022 8.80761 5.2103 8.10342 5.17942L7.95677 5.17637C7.61186 5.17637 7.37595 5.29364 7.14564 5.52096C7.11122 5.55494 7.08177 5.59441 7.05649 5.64002C7.00039 5.74121 6.97246 5.85531 6.96401 5.92949L6.96094 5.96554L4.96176 6.02293L4.96141 6.0062C4.9558 5.62441 5.03972 5.15297 5.3073 4.6703C5.42262 4.46229 5.56639 4.26958 5.7407 4.09754C6.31633 3.52938 7.02644 3.17637 7.95677 3.17637Z'
                    fill='#242934'
                />
            </g>
            <defs>
                <clipPath id='clip0_2618_8845'>
                    <rect width='16' height='16' fill='white' />
                </clipPath>
            </defs>
        </svg>
    )
}
