export const linearGradient = (
    angle: number,
    colors: string[],
    padding: {
        left?: number // 0 - 100
        right?: number // 0 - 100
    } = {},
) => {
    const firstColour = colors[0]
    const lastColour = colors[colors.length - 1]

    const padLeft = padding.left || 0
    const padRight = padding.right || 0

    const duration = 100 - padLeft - padRight

    const steps = []

    if (padLeft) {
        steps.push(`${firstColour} 0%`)
    }

    colors.forEach((color, index) => {
        const step = (duration / (colors.length - 1)) * index + padLeft
        steps.push(`${color} ${step}%`)
    })

    if (padRight) {
        steps.push(`${lastColour} 100%`)
    }

    return `linear-gradient(${angle}deg, ${steps.join(', ')})`
}
