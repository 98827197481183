import { get } from '@snsw-gel/utils'
import {
    BreakpointsDict,
    ObjToVars,
    ThemeContract,
    ThemeContractConfig,
    Breakpoint,
    ThemeImplConfig,
    ThemeImplementation,
    MediaQueryApi,
} from './themeTypes'
import { pxToRem } from './pxToRem'

export class VarSetter {
    static $$type = '$VarSetter'
    static counter = 0

    static getValueAsString(next: string | number | { toString(): string }) {
        switch (typeof next) {
            case 'string':
            case 'number':
                return next
            case 'object':
                return next.toString()
            default:
                throw new Error(`Invalid type for var setter: ${typeof next}`)
        }
    }

    [k: string]: any
    /**
     * The name of the variable. Not used in CSS eg `var-name`
     */
    key: string
    id = VarSetter.counter++
    /**
     * The property name of the variable, eg `--var-name`
     */
    var: string
    defaultVal?: string;
    [Symbol.toPrimitive]() {
        return this.toString()
    }
    [Symbol.toStringTag] = VarSetter.$$type

    constructor(hint: string | number, defaultValue?: string) {
        // @ts-ignore - we're using a symbol as a key here so it is not enumerable
        this[VarSetter.$$type] = true
        this.key = hint ? `${hint}-${this.id}` : `var-${this.id}`
        this.var = `--${this.key}`
        this.defaultVal = defaultValue
    }

    set(next: string | number | { toString(): string } | undefined): string {
        if (next === undefined) {
            return ''
        }
        return `${this.var}: ${VarSetter.getValueAsString(next)};`
    }
    setStyle(next: string | number | { toString(): string } | undefined) {
        if (next === undefined) {
            return {}
        }
        return {
            [this.var]: VarSetter.getValueAsString(next),
        }
    }
    toString() {
        if (this.defaultVal !== undefined) {
            return `var(${this.var}, ${VarSetter.getValueAsString(
                this.defaultVal,
            )})`
        } else {
            return `var(${this.var})`
        }
    }
}

export function createVar(name: string | number, defaultValue?: string) {
    return new VarSetter(name, defaultValue)
}

export function isVarSetter(obj: any): obj is VarSetter {
    return typeof obj === 'object' && obj[VarSetter.$$type]
}

export function fallbackVar(cssVar: VarSetter, value: string | number) {
    return `var(${cssVar}, ${value})`
}

function objToVars<T extends {}>(obj: T, path: string[] = []) {
    const result = {} as ObjToVars<T>
    const keys = Object.keys(obj)

    let hasMqKey = false
    let hasVarKey = false

    for (const key of keys) {
        if (key.startsWith('@')) {
            hasMqKey = true
        } else {
            hasVarKey = true
        }

        if (hasMqKey && hasVarKey) {
            throw new Error('Do not mix @media queries with other properties')
        }

        // we want to check the rest of the object to make sure there is no mixed key types
        if (hasMqKey) {
            continue
        }

        const value = (obj as any)[key]
        const currentPath: string[] = [...path, key]

        if (typeof value === 'object') {
            // @ts-ignore
            result[key] = objToVars(value, currentPath)
        } else {
            // @ts-ignore
            result[key] = createVar(currentPath.join('--'))
        }
    }

    if (hasMqKey) {
        return createVar(path.join('--'))
    }

    if ((result as any).default) {
        return Object.assign(() => (result as any).default.toString(), result, {
            toString() {
                return (result as any).default.toString()
            },
        })
    } else {
        return result
    }
}

export function createThemeContract<Config extends ThemeContractConfig>(
    config: Config,
) {
    const bps = Object.entries(config.breakpoints) as [
        keyof Config['breakpoints'],
        Breakpoint,
    ][]

    const vars = objToVars(config.theme) as unknown as ObjToVars<
        Config['theme']
    >

    const contract = {
        ...config,
        config,
        'theme': vars,
        'breakpoints[]': bps.map(([name, value]) => ({
            ...value,
            name,
        })),
        'mq': createMq(config.breakpoints),
        vars,
    }

    // @ts-ignore
    if (process.env.NODE_ENV === 'development') {
        // @ts-ignore
        global.contract = contract
    }

    return contract as ThemeContract<Config>
}

type Prefix<Val, Prefix extends string> = Val extends string
    ? `${Prefix}${Val}`
    : never

export function createMq<Breakpoints extends BreakpointsDict>(
    breakpoints: Breakpoints,
): MediaQueryApi<Breakpoints> {
    type Keys = keyof Breakpoints
    const width = (value: Keys | number) => {
        const rendered =
            typeof value === 'number' ? value : breakpoints[value].min
        if (typeof rendered === 'number') {
            return `${rendered}px`
        }
        return rendered
    }

    const api = {
        min: (breakpoint: Keys | number) =>
            `@media (min-width: ${width(breakpoint)})`,
        max: (breakpoint: Keys | number) =>
            `@media (max-width: ${width(breakpoint)})`,
        minmax: (min: Keys | number, max: keyof Breakpoints | number) =>
            `@media (min-width: ${width(min)}) and (max-width: ${width(max)})`,
        print: () => '@media (print)',
        dark: () => '@media (prefers-color-scheme: dark)',
        light: () => '@media (prefers-color-scheme: light)',
        highContrast: () =>
            '@media (-ms-high-contrast: active), (forced-colors: active)',
    }

    const mq = (obj: {
        [k in Prefix<Keys, '@'>]?: string | number | VarSetter
    }) => {
        const result = {}

        for (const key of Object.keys(obj)) {
            const breakpoint = key.slice(1)
            // @ts-ignore
            result[api.min(breakpoint)] = obj[key]
        }

        return result
    }

    return Object.assign(mq, api)
}

function* walkPaths(
    objA: { [k: string]: any },
    path: string[] = [],
): Generator<[string[], VarSetter]> {
    const objAKeys = Object.keys(objA)

    for (const key of objAKeys) {
        const value = objA[key]
        const currentPath = [...path, key]
        const valueType = typeof value

        if (
            (valueType === 'object' || valueType === 'function') &&
            !isVarSetter(value)
        ) {
            yield* walkPaths(value, currentPath)
        } else {
            yield [currentPath, value]
        }
    }
}

function isMqObject(obj: any): obj is { [k in Prefix<'@', string>]: any } {
    if (typeof obj !== 'object') {
        return false
    }
    for (const prop in obj) {
        if (prop.startsWith('@')) {
            return true
        }
    }
    return false
}

const varRegex = /(\$[a-zA-Z0-9.\-]+)/g

const themeSet = new Set<string>()

export function createTheme<Config extends ThemeContractConfig>(
    contract: ThemeContract<Config>,
    theme: ThemeImplConfig<Config>,
) {
    const className = `theme-${theme.name}`

    if (themeSet.has(className)) {
        throw new Error(`Theme ${theme.name} already exists`)
    }

    return {
        contract,
        config: theme,
        className,
        matches: theme.matches,
        type: theme.type,
    } satisfies ThemeImplementation<Config>
}

export function getThemeStyles<Config extends ThemeContractConfig>(
    theme: ThemeImplementation<Config>,
) {
    const styles = [] as string[]

    const getValue = (str: string | number) => {
        if (typeof str === 'string' && varRegex.test(str)) {
            return str.replace(varRegex, match => {
                const setter = get(
                    theme.contract.vars,
                    match.slice(1).split('.'),
                )
                if (!setter) {
                    throw new Error(`No var found for ${match}`)
                }
                return setter.toString()
            })
        }
        if (typeof str === 'number') {
            return pxToRem(str)
        }
        return str
    }

    // Gets the variable from the contract then tries to find a value in either the theme or the contract to fill the variable
    for (const [path, varSetter] of walkPaths(theme.contract.theme)) {
        let value = get(theme.config, path)

        if (value === undefined || value === '' || value === null) {
            value = get(theme.contract.config.theme, path)
        }

        if (value === undefined || value === '' || value === null) {
            // throw new Error(`No value found for path ${path.join('.')}`)
            value = theme.contract.vars.colors.unset
        }

        if (!varSetter) {
            throw new Error(`No varSetter found for path ${path.join('.')}`)
        }

        if (value === undefined || value === '' || value === null) {
            throw new Error(`No value found for path ${path.join('.')}`)
        }

        if (isMqObject(value)) {
            for (const [mq, mqValue] of Object.entries(value)) {
                styles.push(`
                    ${theme.contract.mq.min(mq.replace('@', '') as any)} {
                        ${varSetter.var}: ${getValue(mqValue)};
                    }
                `)
            }
            continue
        } else {
            styles.push(`${varSetter.var}: ${getValue(value)};`)
        }
    }

    return styles
}

export function getThemeCssContent<Config extends ThemeContractConfig>(
    theme: ThemeImplementation<Config>,
) {
    return getThemeStyles(theme).join('')
}

export function getThemeCss<Config extends ThemeContractConfig>(
    theme: ThemeImplementation<Config>,
) {
    return `
        .${theme.className} {
            ${getThemeCssContent(theme)}
        }
    `
}

export function printThemesCss(...themes: ThemeImplementation<any>[]) {
    // Applies "first/default" theme last because in CSS the last equally specific selector wins
    const lines = []

    const themesInSpecificityOrder = themes.slice().reverse()

    if (themes.length) {
        lines.push(`:root, .default-theme { ${getThemeCssContent(themes[0])} }`)
    }

    for (const theme of themesInSpecificityOrder) {
        theme.matches.forEach(match => {
            lines.push(`${match} { :root { ${getThemeCssContent(theme)} } }`)
        })
    }

    for (const theme of themesInSpecificityOrder) {
        lines.push(`.${theme.className} { ${getThemeCssContent(theme)} }`)
    }

    return lines.join('\n')
}
