import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconDownloadProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconDownload = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconDownloadProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M15 16H1a1 1 0 01-1-1v-5h2v4h12v-4h2v5a1 1 0 01-1 1z' />
            <path d='M8 11.21a1 1 0 01-1-1V1a1 1 0 012 0v9.21a1 1 0 01-1 1z' />
            <path d='M8 12a1 1 0 01-.71-.29l-4-4a1 1 0 011.42-1.42L8 9.59l3.29-3.3a1 1 0 111.42 1.42l-4 4A1 1 0 018 12z' />
        </svg>
    )
}
